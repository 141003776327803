import NavbarComponent from "../components/NavbarComponent";
import { getStates, getDivisionHierarchy, getSlugFromName, getDivisions, getAllHighways, getAllTags } from "../services/LocationsService";
import { useState, useEffect } from "react";
import SelectDropdownComponent from "../components/SelectDropdownComponent";
import { Button, Checkbox, Input, InputGroup, Textarea } from "react-daisyui";
import { isPhoneNumber } from "../utils/validators";
import { getSeller } from "../services/UserService";
import { createPlot, getPlotData, updatePlot } from "../services/LandsService";
import { useParams } from "react-router-dom";
import OverlayComponent from "../components/OverlayComponent";
import NotificationComponent from "../components/NotificationComponent";
import ImageComponent from "../components/ImageComponent";

export default function PlotPage() {
  const params = useParams();
  const [addPage, setAddPage] = useState(false); // stores if page is being added or are we editing existing page.
  useEffect(() => {
    (async () => {
      await fetchStates();
      await fetchAllHighways();
      await fetchAllTags();
      if (params.plot_id) {
        setAddPage(false);
        await fetchLandData(params.plot_id);
      } else {
        setAddPage(true);
      }
    })();
  }, [params]);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });
  const [landId, setLandId] = useState('');
  const [sellerSearchFilter, setSellerSearchFilter] = useState('');
  const [warningMessageFilter, setWarningMessageFilter] = useState('')
  const [sellerSearchIsValid, setSellerSearchIsValid] = useState(false);
  const [sellerSearchInfo, setSellerSearchInfo] = useState({ 'id': '', 'name': '', 'phone_number': '' })
  const [sellerType, setSellerType] = useState("agent")
  const [plotTeams, setPlotTeams] = useState([])
  const [divisionHierarchy, setDivisionHierarchy] = useState([]) // ['district", "mandal", ....]
  const [divisionFilter, setDivisionFilter] = useState([]); // [id,id,id]

  const [plotSizeFilter, setPlotSizeFilter] = useState('');
  const [plotPriceFilter, setPlotPriceFilter] = useState('');
  const [approachRoadFilter, setApproachRoadFilter] = useState(false);
  const [fencingRoadFilter, setFencingRoadFilter] = useState(false);



  const [allHighways, setAllHighways] = useState([]);
  const [landHighways, setLandHighways] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [landNonHighwayTags, setLandNonHighwayTags] = useState([]);

  // const [landBookmark, setLandBookmark] = useState(false);
  const [oneAcrePremium, setOneAcrePremium] = useState(false)
  const [chance, setChance] = useState(false)
  const [siteVerification, setSiteVerification] = useState(false)
  const [investors, setInvestors] = useState(false)



  const [lpNumberType, setLPNumberType] = useState('');
  const [approvalAuthorityType, setApprovalAuthorityType] = useState('');
  const [lsrObtainedFilter, setLSRObtainedFilter] = useState(false);


  const [roadType, setRoadType] = useState("");

  const [saleType, setSaleType] = useState("");
  const [zoneType, setZoneType] = useState("");
  const [useCase, setUseCase] = useState("");

  const [infrastructureType, setInfrastructureType] = useState('');
  const [deedType, setDeedType] = useState('');
  const [plotType, setPlotType] = useState('');
  const [sroType, setSROType] = useState('');
  const [soilType, setSoilType] = useState('');
  const [locationLink, setLocationLink] = useState('');
  const [approximateLocationLink, setApproximateLocationLink] = useState('');
  const [roadWidth, setRoadWidth] = useState(0);
  const [surveyNumber, setSurveyNumber] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');
  const [orrDistance, setOrrDistance] = useState('');
  const [airportDistance, setAirportDistance] = useState(null);
  const [insideOrr, setInsideOrr] = useState(false);
  const [fencingDescription, setFencingDescription] = useState('');
  const [isPhsyicallyVerifiedFilter, setIsPhsyicallyVerifiedFilter] = useState(false);
  const [isChancePropertyFilter, setIsChancePropertyFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState("pending");
  const [engagedBy, setEngagedBy] = useState("");
  const [exposureTypeFilter, setExposureTypeFilter] = useState("public");
  const [landFacingFilter, setLandFacingFilter] = useState("");

  const [layoutType, setLayoutType] = useState("approved");


  const [plotImages, setPlotImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] });
  const [plotSketchImages, setPlotSketchImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] });
  const [eciImages, setECImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] });
  const [schedulePropertyImages, setSchedulePropertyImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] });
  const [layoutPlanImages, setLayoutPlanImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] });
  const [lrsImages, setLRSImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] });

  const [approachRoadImages, setApproachRoadImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [fencingRoadImages, setFencingRoadImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })
  const [waterSourceImages, setWaterSourceImages] = useState({ 'imageFiles': [], 'imageLinks': [], 'removedImageLinks': [] })

  const [divisionOptions, setDivisionOptions] = useState({});
  const statusOptions = [{ 'id': 'pending', 'name': ' Pending' }, { 'id': 'active', 'name': 'Active' }, { 'id': 'sold', 'name': 'Sold' }, { 'id': 'expired', 'name': 'Expired' },];
  const exposureTypeOptions = [{ 'id': 'public', 'name': 'Public' }, { 'id': 'private', 'name': 'Private' }, { 'id': 'super_private', 'name': 'Super Private' }, { 'id': 'sweet_deal', 'name': 'Sweet Deal' }];
  const landFacingOptions = [{ 'id': 'east', 'name': 'East' }, { 'id': 'west', 'name': 'West' }, { 'id': 'north', 'name': 'North' }, { 'id': 'south', 'name': 'South' }];
  const roadTypeOptions = [{ 'id': 'kacha', 'name': 'Kacha' }, { 'id': 'blacktop', 'name': 'Blacktop' }, { 'id': 'formation', 'name': 'Formation' }, { 'id': 'highway_road', 'name': 'Highway Road' }, { 'id': 'cc_road', 'name': 'CC Road' }]
  const saleTypeOptions = [
    { id: "outright", name: "Outright" },
    { id: "jv", name: "JV" },
    { id: "development", name: "Development" },
    { id: "built-to-suit", name: "Built to Suit" },
    { id: "others", name: "Others" }
  ];
  const useCaseOptions = [
    { id: "investment", name: "Investment" },
    { id: "agriculture", name: "Agriculture" },
    { id: "commercial", name: "Commercial" },
    { id: "industrial", name: "Industrial" },
    { id: "others", name: "Others" }
  ]
  const sellerTypeOptions = [{ 'id': 'agent', 'name': 'Agent' }, { 'id': 'owner', 'name': 'Owner' }, { 'id': 'facilitated', 'name': 'Facilitated' }]
  const engagedByOptions = [
    { id: "Shruthi", name: "Shruthi" },
    { id: "Deepika", name: "Deepika" },
    { id: "Pavan", name: "Pavan" },
    { id: "Vishal", name: "Vishal" },
    { id: "Parimala", name: "Parimala" },
    { id: "Keerthana", name: "Keerthana" },
    { id: "Vijay", name: "Vijay" },
    { id: "Ramakrishna", name: "Ramakrishna" },
    { id: "Jagadeesh", name: "Jagadeesh" },
    { id: "Dinesh", name: "Dinesh" },
    { id: "Ramu", name: "Ramu" },
    { id: "Kamlesh", name: "Kamlesh" },
    { id: "Venu", name: "Venu" },
  ];

  // let stateSpecifics = {
  //   hasDistanceFromOrr: false,
  // };

  const fetchLandData = async (plot_id) => {
    setIsLoading(true);
    try {
      const data = await getPlotData(plot_id);

      setLandId(data.id);
      // in division name get the object that has division_type as state
      const stateSlug = getSlugFromName(data.division_info[0].name);

      const hierarchy = await fetchDivisionHierarchy(stateSlug);

      hierarchy.forEach((division, index) => {
        // get the division options for the division
        const divisionId = data.division_info[index].id;
        (async () => {
          await fetchDivisionOptions(divisionId, division);
        })();
      })

      setDivisionFilter(data.division_info.map((division) => {
        return division.id
      }));

      setPlotTeams(data.plot_teams.map(team => ({ 'account': team.account, 'membership_type': team.membership_type, 'is_primary': team.is_primary })))

      setPlotSizeFilter(data.total_plot_size);
      setPlotPriceFilter(data.price_per_square_yard);

      setSaleType(data.sale_type);
      setUseCase(data.use_case);
      setZoneType(data.zone_type);

      console.log("PlotPriceFilter", plotPriceFilter)
      setAdditionalInformation(data.description);
      setEngagedBy(data.engaged_by || "");
      setOrrDistance(data.distance_from_orr);
      setAirportDistance(data.distance_from_airport);
      console.log("insideOrr", data.inside_orr)
      setInsideOrr(data.inside_orr);
      setWarningMessageFilter(data.disclaimer)
      setIsChancePropertyFilter(data.is_chance);
      setStatusFilter(data.status || 'pending');
      setExposureTypeFilter(data.exposure_type || 'public');
      setLandFacingFilter(data.facing);
      setIsPhsyicallyVerifiedFilter(data.is_physically_verified);
      setRoadWidth(data.abutting_road_length)
      setRoadType(data.abutting_road_type)
      setApproachRoadFilter(data.abutting_road);
      setFencingRoadFilter(data.fencing);
      setLayoutType(data.layout_type || 'approved');
      setLPNumberType(data.lp_number || '');
      setApprovalAuthorityType(data.approval_authority || '');
      setLSRObtainedFilter(data.lrs_obtained || false);
      setInfrastructureType(data.infrastructure || '');
      setDeedType(data.deed_number || '');
      setPlotType(data.plot_number || '');
      setSROType(data.sro_number || '');
      setSoilType(data.soil_type || '');

      setLocationLink(data.location_link || '');
      setApproximateLocationLink(data.approximate_location_link || '');
      setSurveyNumber(data.survey_number || '');
      setFencingDescription(data.fencing_description)

      // setTags(data.tags.map(tag => tag.name));
      const allTmpLandHighways = [];
      const allTmpNonLandHighwaysTags = [];

      if (data.plot_tags?.length) {
        data.plot_tags.forEach(tag => {
          if (tag.tag_type == 'highway') {
            allTmpLandHighways.push(tag.slug);
          } else {
            allTmpNonLandHighwaysTags.push(tag);
          }

        });
      }
      setLandHighways(allTmpLandHighways);
      setLandNonHighwayTags(allTmpNonLandHighwaysTags);
      setOneAcrePremium(data.plot_bookmark_data?.one_acre_premium)
      setChance(data.plot_bookmark_data?.chance)
      setSiteVerification(data.plot_bookmark_data?.site_verification)
      setInvestors(data.plot_bookmark_data?.investors || false)

      console.log("data received", data)

      setPlotImages({ 'imageFiles': [], 'imageLinks': data.plot_media?.filter(item => item.category === 'plot_images') || [], 'removedImageLinks': [] });
      setPlotSketchImages({ 'imageFiles': [], 'imageLinks': data.plot_media?.filter(item => item.category === 'plot_sketch') || [], 'removedImageLinks': [] });
      setECImages({ 'imageFiles': [], 'imageLinks': data.plot_media?.filter(item => item.category === 'ec_images') || [], 'removedImageLinks': [] });
      setSchedulePropertyImages({ 'imageFiles': [], 'imageLinks': data.plot_media?.filter(item => item.category === 'schedule_of_property') || [], 'removedImageLinks': [] });
      setLayoutPlanImages({ 'imageFiles': [], 'imageLinks': data.plot_media?.filter(item => item.category === 'layout_plan') || [], 'removedImageLinks': [] });
      setLRSImages({ 'imageFiles': [], 'imageLinks': data.plot_media?.filter(item => item.category === 'lrs_proceeding') || [], 'removedImageLinks': [] });


      setFencingRoadImages({ 'imageFiles': [], 'imageLinks': data.plot_media?.filter(item => item.category === 'fencing') || [], 'removedImageLinks': [] });
      setApproachRoadImages({ 'imageFiles': [], 'imageLinks': data.plot_media?.filter(item => item.category === 'abutting_road') || [], 'removedImageLinks': [] });



    } catch (error) {
      console.log(error);
      triggerNotification(`Unable to fetch plot with id: ${plot_id}`, 'error');
    }
    setIsLoading(false);
  }

  // fetches all states that are available and updates the stateOptions with the results
  const fetchStates = async () => {
    const data = await getStates();
    const options = data.results.map((stateObj) => ({ 'id': stateObj.id, 'name': stateObj.name, 'slug': stateObj.slug }));

    setDivisionOptions({ 'states': options });
  }

  const fetchDivisionHierarchy = async (stateSlug) => {
    const data = await getDivisionHierarchy({
      'state_slug': stateSlug
    });
    setDivisionHierarchy(data)
    return data;
  }

  const fetchDivisionOptions = async (parentDivisionId, divisionType) => {
    // fetch divisions based on divisionType and parentDivisionId
    const data = await getDivisions({
      'parent_id': parentDivisionId,
      'division_type': divisionType
    });

    const nextDivision = divisionType;
    const index = divisionHierarchy.indexOf(divisionType);
    setDivisionOptions(prev => {
      const newOptions = {
        ...prev,
        [nextDivision]: data.results || [],
        // Clear all subsequent division options
        ...divisionHierarchy.slice(index + 1).reduce((acc, div) => ({ ...acc, [div]: [] }), {})
      }

      return newOptions;
    });
    // return data;
  }

  const fetchAllHighways = async () => {
    const data = await getAllHighways();
    if (data.length > 0) {
      setAllHighways(data);
    }
  }

  const fetchAllTags = async () => {
    const data = await getAllTags();
    if (data.count) {
      setAllTags(data.results);
    }
  }


  const divisionOnChangeHandler = async (division, index, newValue) => {
    const castedNewValue = parseInt(newValue);
    console.log(newValue, castedNewValue)
    if (castedNewValue === 0) {
      setDivisionHierarchy([])
      setDivisionFilter([])
      // clear all the division options except states 
      setDivisionOptions({ 'states': divisionOptions.states })
    } else {
      setDivisionFilter((prev) => {
        const newFilter = [...prev.slice(0, index), castedNewValue];
        // Clear subsequent divisions when a higher-level division changes
        return newFilter;
      });

      // Fetch new options for the next division level
      if (index === 0) {
        // If state changed, fetch new division hierarchy and district options
        const stateSlug = divisionOptions.states.find(state => state.id === castedNewValue)?.slug;
        if (stateSlug) {
          const newHierarchy = await fetchDivisionHierarchy(stateSlug);
          setDivisionHierarchy(newHierarchy);

          if (newHierarchy.length > 0) {
            fetchDivisionOptions(castedNewValue, newHierarchy[0]);
          }
        }
      } else {
        // For other levels, fetch options for the next level
        const nextDivision = divisionHierarchy[index];
        console.log("nextDivision", nextDivision)
        if (nextDivision) {
          await fetchDivisionOptions(castedNewValue, nextDivision);
        }
      }
    }
  };

  const sellerPhoneChangeHandler = async (event) => {
    setSellerSearchFilter(event.target.value);
  }
  useEffect(() => {
    if (isPhoneNumber(sellerSearchFilter)) {
      setSellerSearchIsValid(true);
    } else {
      setSellerSearchIsValid(false);
    }
  }, [sellerSearchFilter]);

  const warningMessageChangeHandler = async (event) => {
    setWarningMessageFilter(event.target.value);
  }





  const sellerSearchHandler = async (event) => {
    try {
      const data = await getSeller({ 'phone_number': '91' + sellerSearchFilter })
      if (data) {
        setSellerSearchInfo(() => ({ 'id': data.id, 'name': data.name, 'phone_number': data.phone_number }));
      } else {
        setSellerSearchInfo(() => ({ 'id': '', 'name': '', 'phone_number': '' }))
      }
    } catch (error) {
      setSellerSearchInfo(() => ({ 'id': '', 'name': '', 'phone_number': '' }))
    }
  };
  const sellerTypeChangeHandler = async (newValue) => {
    setSellerType(newValue);
  }
  const addSellerHandler = async () => {
    if (sellerSearchInfo.id) {
      setPlotTeams((prevPlotTeams) => {
        const tmpPlotTeams = prevPlotTeams.length > 0 ? [...prevPlotTeams] : [];
        let newPlotTeams = [];
        if (tmpPlotTeams.length > 0) {
          newPlotTeams = tmpPlotTeams.filter(eachTeam => {
            if (eachTeam.account !== sellerSearchInfo.id && eachTeam.membership_type !== sellerType) {
              return true;
            } else {
              return false;
            }
          })
        }
        newPlotTeams.push({
          'account': {
            'name': sellerSearchInfo.name, 'phone_number': sellerSearchInfo.phone_number,
            'id': sellerSearchInfo.id
          }, 'membership_type': sellerType, 'is_primary': newPlotTeams.length === 0 ? true : false,
        });
        return newPlotTeams;
      })
    }
  }
  const isPrimaryChangeHandler = async (membershipType) => {
    setPlotTeams((prevPlotTeams) => {
      return prevPlotTeams.map((plotTeam) => {
        if (plotTeam.membership_type === membershipType) {
          plotTeam.is_primary = true;
        }
        else {
          plotTeam.is_primary = false;
        }
        return plotTeam;
      })
    })
  }

  const statusChangeHandler = async (newValue) => {
    setStatusFilter(newValue);
  }
  const engagedByChangeHandler = async (newValue) => {
    setEngagedBy(newValue);
  };
  const exposureTypeChangeHandler = async (newValue) => {
    setExposureTypeFilter(newValue);
  }

  const landFacingChangeHandler = async (newValue) => {
    setLandFacingFilter(newValue);
  }

  const roadTypeChangeHandler = async (newValue) => {

    setRoadType(newValue);

  }



  const infrastructureChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setInfrastructureType(value);
    } else {
      setInfrastructureType('');
    }
  }

  const lpNumberChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setLPNumberType(value);
    } else {
      setLPNumberType('');
    }
  }

  const approvalAuthorityChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setApprovalAuthorityType(value);
    } else {
      setApprovalAuthorityType('');
    }
  }


  const deedChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setDeedType(value);
    } else {
      setDeedType('');
    }
  }

  const plotChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setPlotType(value);
    } else {
      setPlotType('');
    }
  }

  const sroChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setSROType(value);
    } else {
      setSROType('');
    }
  }

  const soilChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setSoilType(value);
    } else {
      setSoilType('');
    }
  }
  const useCaseChangeHandler = async (newValue) => {
    setUseCase(newValue);
  }
  const saleTypeChangeHandler = async (newValue) => {
    setSaleType(newValue);
  }
  const zoneTypeChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setZoneType(value);
    } else {
      setZoneType("");
    }
  }


  const handleWheel = async (event) => {
    event.target.blur();
    // event.preventDefault();
    // event.stopPropagation();
  }


  const plotSizeChangeHandler = async (event) => {
    if (/^\d+(\.\d+)?$/.test(event.target.value) || !(event.target.value)) {
      setPlotSizeFilter(event.target.value);
    }
  }
  const plotPriceChangeHandler = async (event) => {
    if (/^\d+(\.\d+)?$/.test(event.target.value) || !(event.target.value)) {
      setPlotPriceFilter(event.target.value);
    }
  }


  const approachRoadChangeHandler = async () => {
    setApproachRoadFilter(() => !approachRoadFilter);
    if (approachRoadFilter) {
      setRoadWidth(0)
      setRoadType('')
    }

  }

  const fencingRoadChangeHandler = async () => {
    setFencingRoadFilter(() => !fencingRoadFilter);
  }





  // const lsrObtainedChangeHandler = async () => {
  //     setLSRObtainedFilter(() => !lsrObtainedFilter);

  // }

  const lsrObtainedChangeHandler = (event) => {
    setLSRObtainedFilter(event.target.checked);
    // Clear the images if checkbox is unchecked
    if (!event.target.checked) {
      setLRSImages({ imageFiles: [], imageLinks: [], removedImageLinks: [] });
    }
  };


  const anyHighwayChangeHandler = async (event, highway) => {
    setLandHighways((prevLandHighways) => {
      const tmpLandHighways = [...prevLandHighways];

      if (event.target.value === 'false') {
        tmpLandHighways.push(highway.slug);
      } else {
        const index = tmpLandHighways.indexOf(highway.slug);
        if (index > -1) {
          tmpLandHighways.splice(index, 1);
        }
      }

      return tmpLandHighways;
    });
  }

  // const landBookmarkChangeHandler = async () => {
  //   setLandBookmark(() => !landBookmark);
  // }

  const oneAcrePremiumChangeHandler = async () => {
    setOneAcrePremium(() => !oneAcrePremium);
  }

  const chanceChangeHandler = async () => {
    setChance(() => !chance);
  }

  const siteVerificationChangeHandler = async () => {
    setSiteVerification(() => !siteVerification);
  }


  const investorsChangeHandler = async () => {
    setInvestors(() => !investors);
  }




  const locationLinkChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setLocationLink(value);
    } else {
      setLocationLink('');
    }
  }

  const approximateLocationLinkChangeHandler = async (event) => {
    const value = event.target.value;
    if (value) {
      setApproximateLocationLink(value);
    } else {
      setApproximateLocationLink('');
    }
  }





  const roadWidthChangeHandler = async (event) => {
    if (/^\d*$/.test(event.target.value)) {
      setRoadWidth(event.target.value);
    }
  }
  const surveyNumberChangeHandler = async (event) => {
    setSurveyNumber(event.target.value);

  }

  const additionalInformationChangeHandler = async (event) => {
    setAdditionalInformation(event.target.value);
  }

  // const orrDistanceChangeHandler = async (event) => {
  //   setOrrDistance(event.target.value);
  // }
  const airportDistanceChangeHandler = async (event) => {
    setAirportDistance(event.target.value);
  }
  const insideOrrChangeHandler = async (event) => {
    setInsideOrr(event.target.checked)
  }

  const fencingDescriptionChangeHandler = async (event) => {
    setFencingDescription(event.target.value);

  }

  const isPhsyicallyVerifiedChangeHandler = async () => {
    setIsPhsyicallyVerifiedFilter(() => !isPhsyicallyVerifiedFilter);
  }

  const isChancePropertyChangeHandler = async () => {
    setIsChancePropertyFilter(() => !isChancePropertyFilter);
  }

  const handleLayoutChange = async (event) => {
    setLayoutType(event.target.value);
  }

  const getUpdatedPlotData = () => {
    const plotParams = {};
    plotParams['plot_teams'] = [
      ...plotTeams.map(team => ({ 'name': team.name, 'account': team.account.id, 'membership_type': team.membership_type, 'is_primary': team.is_primary }))
    ]

    if (divisionFilter.length > divisionHierarchy.length) {
      plotParams['division'] = divisionFilter
    }

    plotParams["sale_type"] = saleType;
    plotParams["use_case"] = useCase;
    plotParams["zone_type"] = zoneType;

    plotParams["layout_type"] = layoutType !== 'approved' ? layoutType : 'approved';

    plotParams["lp_number"] = lpNumberType !== '' ? lpNumberType : null;
    plotParams['approval_authority'] = approvalAuthorityType !== '' ? approvalAuthorityType : null;
    plotParams['lrs_obtained'] = lsrObtainedFilter !== '' ? lsrObtainedFilter : false;

    console.log("-----layout_type-----", plotParams["layout_type"])

    plotParams["infrastructure"] = infrastructureType !== '' ? infrastructureType : null;
    plotParams["deed_number"] = deedType !== '' ? deedType : null;
    plotParams["plot_number"] = plotType !== '' ? plotType : null;
    plotParams["sro_number"] = sroType !== '' ? sroType : null;
    plotParams["soil_type"] = soilType !== '' ? soilType : null;


    plotParams['disclaimer'] = warningMessageFilter !== '' ? warningMessageFilter : null;

    if (statusFilter !== "0") {
      plotParams["status"] = statusFilter
    }
    if (exposureTypeFilter !== "0") {
      plotParams["exposure_type"] = exposureTypeFilter
    }

    // plotParams["total_land_size_in_acres"] = {
    //     'acres': parseInt(landSizeAcresFilter) || 0,
    //     'guntas': parseFloat(landSizeGuntasFilter) || 0
    // }

    // plotParams['price_acre'] = { 'price': parseFloat(landPriceFilter), 'price_type': 'lakh' }
    plotParams["price_per_square_yard"] = parseInt(plotPriceFilter) || 0;
    plotParams["total_plot_size"] = parseInt(plotSizeFilter) || 0;


    plotParams['abutting_road'] = approachRoadFilter;
    plotParams['abutting_road_length'] = roadWidth;
    plotParams['abutting_road_type'] = roadType;
    plotParams['fencing'] = fencingRoadFilter;
    plotParams['engaged_by'] = engagedBy.length > 0 ? engagedBy : null;



    plotParams['location_link'] = locationLink;
    plotParams['approximate_location_link'] = approximateLocationLink;
    plotParams['survey_number'] = surveyNumber;
    plotParams['description'] = additionalInformation;
    plotParams['distance_from_airport'] = airportDistance ? airportDistance : null;
    plotParams['distance_from_orr'] = orrDistance ? orrDistance : null;
    plotParams['inside_orr'] = insideOrr
    plotParams['is_physically_verified'] = isPhsyicallyVerifiedFilter;
    plotParams['facing'] = landFacingFilter;
    plotParams['fencing_description'] = fencingDescription;
    plotParams['land_media'] = []
    plotParams['plot_media'] = []
    plotParams['removed_media_files'] = []
    plotParams['plot_bookmark_data'] = { 'one_acre_premium': oneAcrePremium, 'chance': chance, 'site_verification': siteVerification, 'investors': investors }


    let requiredTags = [];
    if (!addPage) {
      allTags.forEach(eachTag => {
        if (eachTag.tag_type !== 'highway') {

        } else {
          if (landHighways.includes(eachTag.slug)) {
            requiredTags.push(eachTag);
          }
        }
      });
      requiredTags = [...landNonHighwayTags, ...requiredTags];
    } else {
      allTags.forEach(eachTag => {
        if (landHighways.includes(eachTag.slug)) {
          requiredTags.push(eachTag);
        }
      });
    }

    // plotParams['tags'] = requiredTags;
    plotParams['plot_tags'] = requiredTags || '';



    console.log('plot_media length plotParams', plotSketchImages.imageFiles.length)
    if (plotSketchImages.imageFiles.length > 0) {
      for (const imageFileObj of plotSketchImages.imageFiles) {
        plotParams['plot_media']?.push({ 'media_type': 'image', 'category': 'plot_sketch', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
      console.log('plot_media plotParams', plotParams['plot_media'])
    }
    if (plotSketchImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(plotSketchImages.removedImageLinks);
    };

    if (plotImages.imageFiles.length > 0) {
      for (const imageFileObj of plotImages.imageFiles) {
        plotParams['plot_media']?.push({ 'media_type': 'image', 'category': 'plot_images', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
      console.log('plot_media plotParams', plotParams['plot_media'])
    }
    if (plotImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(plotImages.removedImageLinks);
    };

    if (eciImages.imageFiles.length > 0) {
      for (const imageFileObj of eciImages.imageFiles) {
        plotParams['plot_media']?.push({ 'media_type': 'image', 'category': 'ec_images', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
      console.log('plot_media plotParams', plotParams['plot_media'])
    }
    if (eciImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(eciImages.removedImageLinks);
    };

    if (schedulePropertyImages.imageFiles.length > 0) {
      for (const imageFileObj of schedulePropertyImages.imageFiles) {
        plotParams['plot_media']?.push({ 'media_type': 'image', 'category': 'schedule_of_property', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
      console.log('plot_media plotParams', plotParams['plot_media'])
    }
    if (schedulePropertyImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(schedulePropertyImages.removedImageLinks);
    };

    if (layoutPlanImages.imageFiles.length > 0) {
      for (const imageFileObj of layoutPlanImages.imageFiles) {
        plotParams['plot_media']?.push({ 'media_type': 'image', 'category': 'layout_plan', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
      console.log('plot_media plotParams', plotParams['plot_media'])
    }
    if (layoutPlanImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(layoutPlanImages.removedImageLinks);
    };

    if (lrsImages.imageFiles.length > 0) {
      for (const imageFileObj of lrsImages.imageFiles) {
        plotParams['plot_media']?.push({ 'media_type': 'image', 'category': 'lrs_proceeding', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
      console.log('plot_media plotParams', plotParams['plot_media'])
    }
    if (lrsImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(lrsImages.removedImageLinks);
    };


    if (approachRoadImages.imageFiles.length > 0) {
      for (const imageFileObj of approachRoadImages.imageFiles) {
        plotParams['plot_media']?.push({ 'media_type': 'image', 'category': 'abutting_road', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
      console.log('plot_media plotParams', plotParams['plot_media'])
    }
    if (approachRoadImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(approachRoadImages.removedImageLinks);
    };

    if (fencingRoadImages.imageFiles.length > 0) {
      for (const imageFileObj of fencingRoadImages.imageFiles) {
        plotParams['plot_media']?.push({ 'media_type': 'image', 'category': 'fencing', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (fencingRoadImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(fencingRoadImages.removedImageLinks);
    }


    if (waterSourceImages.imageFiles.length > 0) {
      for (const imageFileObj of waterSourceImages.imageFiles) {
        plotParams['land_media'].push({ 'media_type': 'image', 'category': 'water_source', 'image': { 'file_data': imageFileObj.src, 'file_name': imageFileObj.name } })
      }
    }
    if (waterSourceImages.removedImageLinks.length > 0) {
      plotParams['removed_media_files'] = plotParams['removed_media_files'].concat(waterSourceImages.removedImageLinks);
    }

    return plotParams

  }

  const plotSubmitHandler = async () => {
    console.log("In and submit handler")
    if (plotTeams.length === 0 || divisionFilter.length !== divisionHierarchy.length + 1) {

      setErrorMessage('Mandatory fields are missing. Please check Seller, District, Mandal, Village');
    } else {
      setErrorMessage('');
      const plotParams = getUpdatedPlotData();
      console.log("plotParams", plotParams)
      setIsLoading(true);
      try {
        console.log(addPage)
        const data = addPage ? await createPlot(plotParams) : await updatePlot(landId, plotParams);
        // console.log("dataaaaaa",data)
        if (addPage) {
          window.history.replaceState(null, '', `/plots/${data.id}`);
          setLandId(data.id);
          setAddPage(false);

          triggerNotification(`Plot created with id: ${data.id}`, 'success. Please wait for refresh');
        } else {
          triggerNotification('Plot updated. Please wait for refresh', 'success');
        }
        setTimeout(() => {
          window.location.reload()
        }, 3000
        )

      } catch (error) {
        if (error.response.status === 400) {
          setErrorMessage(() => 'Mandatory fields are missing');
        }
        else if (error.response.status === 401) {
          setErrorMessage(() => "You can't cheat me. Please login.");
        } else {
          setErrorMessage(() => "Unknown error. Please contact support.");
        }
        triggerNotification('Operation failed.', 'error');
      }
      setIsLoading(false);
    }
  }

  const triggerNotification = (message, type) => {
    setNotification({ show: true, message: message, type: type });
    setTimeout(() => {
      setNotification({ show: false, message: '', type: '' });
    }, 3000);
  };


  return (
    <div>
      {isLoading && <OverlayComponent />}
      <NavbarComponent />
      <div className="m-5">
        <div className="w-4/5 m-auto">
          <div className="flex w-full items-center justify-center relative">
            <label className="label text-2xl text-bold text-center">
              {addPage ? 'Add Plot' : 'Update Plot'}
            </label>
            {/* <Button className="w-48 bg-blue-200 absolute right-map" onClick={(e) => navigate(`/map`)}>
              View Map
            </Button>
            <Button className="w-48 bg-blue-200 absolute right-0" onClick={(e) => navigate(`/private-map`)}>
              Private Map
            </Button> */}
          </div>



          <div className="flex flex-col w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="flex items-center gap-4 w-full justify-between">
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <label className="label">
                    <span className="label-text">Seller Phone Number</span>
                  </label>
                </div>
                <div className="flex gap-2">
                  <Input type="text" maxLength={10} value={sellerSearchFilter} onChange={sellerPhoneChangeHandler} />
                  <Button onClick={sellerSearchHandler} disabled={!sellerSearchIsValid}>Search</Button>
                </div>
              </div>
              <div className="flex m-auto">
                {!sellerSearchInfo.id && (
                  <label className="label text-sm text-red-500">Seller not available. Search with valid phone number.</label>
                )}
                {sellerSearchInfo.id && (
                  <div>
                    <label className="label text-md font-bold">Seller Information</label>
                    <label className="label text-sm">
                      {sellerSearchInfo.name}, {sellerSearchInfo.phone_number}
                    </label>
                  </div>
                )}
                {/* { !addPage && (<label className="label text-sm">
                                  {sellerAccount.name},{sellerAccount.phone_number}
                                      </label>)} */}
              </div>
              <div className="flex gap-4 ml-2 mr-6">
                <SelectDropdownComponent labelName={'Seller Type'} options={sellerTypeOptions} currValue={sellerType} onChangeHandler={sellerTypeChangeHandler} />
              </div>
              {
                sellerSearchInfo.id && sellerType &&
                <Button color="primary" className="w-fit" onClick={addSellerHandler}>Add</Button>
              }
            </div>
            {plotTeams.length > 0 && <div className="flex flex-row form-control gap-2 w-full m-auto">
              {plotTeams.map((team) => {
                return (
                  <label key={team.account} className="label px-2 shadow-md border rounded-md">
                    <div className="relative">
                      <p className="label text-md font-bold capitalize">{team.membership_type}</p>
                      <p>{team.account.name}, {team.account.phone_number}</p>
                      <input type="radio" name="isPrimary" onChange={() => isPrimaryChangeHandler(team.membership_type)} className="radio absolute top-0 right-0 checked:bg-blue-500" checked={team.is_primary} />
                    </div>
                  </label>
                )
              })
              }
            </div>}
          </div>

          <div className=" border-2 border-solid rounded-xl border-[#ffde59] my-3">
            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">States<span className="text-red-600">*</span></span>
                </label>
                <SelectDropdownComponent labelName='All States' options={divisionOptions['states']} currValue={divisionFilter[0]} onChangeHandler={(value) => divisionOnChangeHandler('state', 0, value)} />
              </div>
              {
                divisionHierarchy.length > 0 && (
                  divisionHierarchy.map((division, index) => {
                    return (
                      <div className="form-control w-full max-w-xs">
                        <label className="label">
                          <span className="label-text">{division}</span>
                        </label>
                        <SelectDropdownComponent labelName={`All ${division}`} options={divisionOptions[division]} currValue={divisionFilter[index + 1]} onChangeHandler={(value) => divisionOnChangeHandler(division, index + 1, value)} />
                      </div>
                    )
                  })
                )
              }
            </div>

            <div className="flex w-full p-4 mt-4 component-preview items-center gap-14 font-sans">

              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Total Plot Size</span>
                </label>
                <InputGroup>
                  <Input type="text" value={plotSizeFilter} onChange={plotSizeChangeHandler} placeholder="0" bordered />
                  <span className="text-sm">Square Yards</span>
                </InputGroup>
              </div>

              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Price per Square Yard(in Rupees)</span>
                </label>
                <InputGroup>
                  <Input type="text" value={plotPriceFilter} onChange={plotPriceChangeHandler} placeholder="0" bordered />
                  <span className="text-sm">Rupees</span>
                </InputGroup>
              </div>
            </div>
          </div>
          {

            // exposureTypeFilter === "private" && (
            <div className=" border-2 border-solid rounded-xl border-[#ffde59] my-3">
              <div className=" flex p-4 mt-4 items-center gap-2 font-sans">

                <div className="max-w-xs">
                  <label className="label">
                    <span className="label-text">Sale Type</span>
                  </label>
                  <SelectDropdownComponent
                    labelName={"Sale Type"}
                    options={saleTypeOptions}
                    currValue={saleType}
                    onChangeHandler={saleTypeChangeHandler}
                  />
                </div>
                <div className="form-control w-full max-w-xs">
                  <label className="label">
                    <span className="label-text">Zone Type</span>
                  </label>
                  <Input
                    type="text"
                    onChange={zoneTypeChangeHandler}
                    value={zoneType}
                  />
                </div>

                <div className="max-w-xs">
                  <label className="label">
                    <span className="label-text">Use Case</span>
                  </label>
                  <SelectDropdownComponent
                    labelName={"Use Case"}
                    options={useCaseOptions}
                    currValue={useCase}
                    onChangeHandler={useCaseChangeHandler}
                  />
                </div>
              </div>
            </div>
            // )
          }


          <div className=" border-2 border-solid rounded-xl border-[#ffde59] my-3">
            <div className=" flex p-4 mt-4 items-center gap-2 font-sans">
              <div className="flex-none text-sm">
                <div className="flex items-center">
                  <Checkbox color="primary" value={approachRoadFilter} onChange={approachRoadChangeHandler} checked={approachRoadFilter} />
                  <label className="ml-2 text-sm">Abutting Road</label>
                </div>
              </div>

              <div className="max-w-xs ml-4">
                <label className="label">
                  <span className="label-text">Road Width</span>
                </label>
                <InputGroup>
                  <Input type="text" onChange={roadWidthChangeHandler} value={roadWidth} bordered />
                  <span className="text-sm">Ft</span>
                </InputGroup>
              </div>

              <div className="max-w-xs ml-6">
                <label className="label">
                  <span className="label-text">Road Type</span>
                </label>
                <SelectDropdownComponent labelName={'Road Type'} options={roadTypeOptions} currValue={roadType} onChangeHandler={roadTypeChangeHandler} />
              </div>
              <ImageComponent multipleImages={false} defaultValues={approachRoadImages} onChangeHandler={setApproachRoadImages} />
            </div>


            <div className="flex w-full p-4 mt-4  items-center gap-4 font-sans">
              <div className=" flex items-center">
                <Checkbox color="primary" value={fencingRoadFilter} onChange={fencingRoadChangeHandler} checked={fencingRoadFilter} />
                <label className="ml-2 text-sm">Fencing</label>
              </div>
              <div className="w-full max-w-md">
                <label className="label">
                  <span className="label-text">Fencing Description</span>
                </label>
                <Textarea className="w-full" type="text" onChange={fencingDescriptionChangeHandler} value={fencingDescription} />
              </div>
              <ImageComponent multipleImages={false} defaultValues={fencingRoadImages} onChangeHandler={setFencingRoadImages} />
            </div>


            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">

              <div className="form-control w-4/5 ">
                <label className="label">
                  <span className="label-text">Infrastructure</span>
                </label>
                <Textarea type="text" onChange={infrastructureChangeHandler} value={infrastructureType} />
                {/* <SelectDropdownComponent labelName={'Crop Type'} options={cropOptions} currValue={cropFilter} onChangeHandler={cropChangeHandler} /> */}
              </div>


            </div>
            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Soil Type</span>
                </label>
                <Input type="text" onChange={soilChangeHandler} value={soilType} />
                {/* <SelectDropdownComponent labelName={'Soil Type'} options={soilOptions} currValue={soilFilter} onChangeHandler={soilChangeHandler} /> */}
              </div>

              <div className="pl-8 pt-10 form-control w-full max-w-xs">
                <SelectDropdownComponent labelName={'Facing'} options={landFacingOptions} currValue={landFacingFilter} onChangeHandler={landFacingChangeHandler} />
              </div>
            </div>
          </div>

          <div className=" border-2 border-solid rounded-xl border-[#ffde59] my-3">


            <div className="flex w-full p-4 mt-4 component-preview items-center gap-6 font-sans">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Distance From Airport (In km)</span>
                </label>
                <Input type="number" min="0"
                  pattern="[0-9]+([.][0-9]+)?"
                  onWheel={
                    handleWheel
                  }
                  onChange={(e) => {
                    if (e.target.value) {
                      const value = parseFloat(e.target.value);
                      setAirportDistance(value);
                    }
                  }}
                  value={airportDistance} />
              </div>
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Distance From ORR (In km)</span>
                </label>
                <Input type="number" min="0"
                  pattern="[0-9]+([.][0-9]+)?"
                  onWheel={
                    handleWheel
                  }
                  onChange={(e) => {
                    if (e.target.value) {
                      const value = parseFloat(e.target.value);
                      if (isNaN(value) || value < 0) {
                        setOrrDistance(value || '');
                      } else {
                        setOrrDistance(value);
                      }
                    } else {
                      setOrrDistance('')
                    }
                  }}
                  value={orrDistance} />
              </div>
              <div className="flex items-center mt-6">
                <Checkbox color="primary" value={insideOrr} onChange={insideOrrChangeHandler} checked={insideOrr} />
                <label className="label">
                  <span className="label-text">Inside ORR</span>
                </label>
              </div>
            </div>
            {divisionFilter[0] === 24 &&
              < div className="flex  p-4 mt-4  items-center gap-2 font-sans">
                <div className="flex flex-none text-sm">
                  <label className="label"><b>National Highway</b></label>
                </div>
                {allHighways.map((highway, index) => (
                  <div className="flex items-center">
                    <Checkbox key={highway.slug} color="primary" value={landHighways.includes(highway.slug)} onChange={e => anyHighwayChangeHandler(e, highway)} checked={landHighways.includes(highway.slug)} />
                    <label className="ml-2 text-sm min-w-full"><span className="capitalize-name">{highway.name}</span></label>
                  </div>
                ))}
              </div>}

            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-4/5">
                <label className="label">
                  <span className="label-text">Exact Location Link</span>
                </label>
                <Input type="text" onChange={locationLinkChangeHandler} value={locationLink} />
              </div>
              <div className="form-control w-4/5">
                <label className="label">
                  <span className="label-text">Approximate Location Link</span>
                </label>
                <Input type="text" onChange={approximateLocationLinkChangeHandler} value={approximateLocationLink} />
              </div>

            </div>

            <div className="flex w-full p-4 mt-4 mb-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-4/5">
                <label className="label">
                  <span className="label-text">Additional Information</span>
                </label>
                <Textarea type="text" onChange={additionalInformationChangeHandler} value={additionalInformation} />
              </div>
            </div>
            <label className="ml-5 "><b>Upload Plot Images</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setPlotImages} defaultValues={plotImages} />


          </div>

          <div className=" border-2 border-solid rounded-x
                    l border-[#ffde59] my-3">
            <div className="flex w-full items-center justify-center">
              <label className="label text-2xl">
                <b> Verification Data</b>
              </label>
            </div>
            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Survey Number</span>
                </label>
                <Input type="text" onChange={surveyNumberChangeHandler} value={surveyNumber} />

              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Disclaimer</span>
                </label>
                <Input type="text" value={warningMessageFilter} onChange={warningMessageChangeHandler} />

              </div>
            </div>

            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-4/5">
                <label className="label">
                  <span className="label-text">Deed Number</span>
                </label>
                <Input type="text" onChange={deedChangeHandler} value={deedType} />
                {/* <SelectDropdownComponent labelName={'Crop Type'} options={cropOptions} currValue={cropFilter} onChangeHandler={cropChangeHandler} /> */}
              </div>
              <div className="form-control w-4/5 ">
                <label className="label">
                  <span className="label-text">SRO Number</span>
                </label>
                <Input type="text" onChange={sroChangeHandler} value={sroType} />
                {/* <SelectDropdownComponent labelName={'Crop Type'} options={cropOptions} currValue={cropFilter} onChangeHandler={cropChangeHandler} /> */}
              </div>
            </div>

            <label className="ml-5"><b>Upload EC Images</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setECImages} defaultValues={eciImages} />

            <label className="ml-5"><b>Upload Schedule of Property Images</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setSchedulePropertyImages} defaultValues={schedulePropertyImages} />

            <label className="ml-5"><b>Upload Plot Sketch Images</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setPlotSketchImages} defaultValues={plotSketchImages} />

            <label className="ml-5"><b>Upload Layout Plan Images</b></label>
            <ImageComponent multipleImages={true} onChangeHandler={setLayoutPlanImages} defaultValues={layoutPlanImages} />

            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <label className="label">
                  <span className="label-text">Plot Number</span>
                </label>
                <Input type="text" onChange={plotChangeHandler} value={plotType} />
                {/* <SelectDropdownComponent labelName={'Crop Type'} options={cropOptions} currValue={cropFilter} onChangeHandler={cropChangeHandler} /> */}
              </div>
            </div>


            <div className="flex w-full ml-4 mb-4  mt-4 component-preview items-center gap-2 font-sans">
              <label className="label"><b>Layout Type</b></label>
            </div>
            <div className="mb-4">
              <label className="ml-4 ">
                <input
                  type="radio"
                  value="approved"
                  checked={layoutType === 'approved'}
                  onChange={handleLayoutChange}
                />
                <span className="ml-2">Approved</span>
              </label>
              <label className="ml-4">
                <input
                  type="radio"
                  value="unapproved"
                  checked={layoutType === 'unapproved'}
                  onChange={handleLayoutChange}
                />
                <span className="ml-2">Unapproved</span>
              </label>
            </div>

            {layoutType === 'approved' && (
              <div className="flex ml-2 w-full p-4 mb-2 component-preview items-center gap-2 font-sans">
                <div className="form-control w-4/5 ">
                  <label className="label">
                    <span className="label-text">LP Number</span>
                  </label>
                  <Input type="text" onChange={lpNumberChangeHandler} value={lpNumberType} />
                  {/* <SelectDropdownComponent labelName={'Crop Type'} options={cropOptions} currValue={cropFilter} onChangeHandler={cropChangeHandler} /> */}
                </div>
                <div className="form-control w-4/5 ">
                  <label className="label">
                    <span className="label-text">Approval Authority</span>
                  </label>
                  <Input type="text" onChange={approvalAuthorityChangeHandler} value={approvalAuthorityType} />
                  {/* <SelectDropdownComponent labelName={'Crop Type'} options={cropOptions} currValue={cropFilter} onChangeHandler={cropChangeHandler} /> */}
                </div>

              </div>
            )}

            {layoutType === 'unapproved' && (
              <div className="flex gap-20 ml-4 mr-6 mb-4">
                <div className="flex items-center ">
                  <Checkbox color="primary" value={lsrObtainedFilter} onChange={lsrObtainedChangeHandler} checked={lsrObtainedFilter} />
                  <label className="ml-2 text-sm min-w-full">LRS Obtained</label>
                </div>

                {lsrObtainedFilter && (
                  <div className="flex items-center">
                    <label className="ml-20"><b>LRS Proceeding Images</b></label>
                    <ImageComponent multipleImages={true} onChangeHandler={setLRSImages} defaultValues={lrsImages} />
                  </div>
                )}

              </div>
            )}



          </div>

          <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans border-2 border-solid rounded-xl border-[#ffde59]">

            <div className="form-control w-full max-w-xs">
              <div className="flex items-center">
                <Checkbox color="primary" value={isPhsyicallyVerifiedFilter} onChange={isPhsyicallyVerifiedChangeHandler} checked={isPhsyicallyVerifiedFilter} />
                <label className="ml-2 text-sm">Physical Verification Completed</label>
              </div>
            </div>

            <div className="form-control w-full max-w-xs">
              <div className="flex items-center">
                <Checkbox color="primary" value={isChancePropertyFilter} onChange={isChancePropertyChangeHandler} checked={isChancePropertyFilter} />
                <label className="ml-2 text-sm">Chance Property</label>
              </div>
            </div>

            <div className="form-control w-full max-w-xs">
              <SelectDropdownComponent labelName={'Status'} options={statusOptions} currValue={statusFilter} onChangeHandler={statusChangeHandler} />
            </div>
            <div className="form-control w-full max-w-xs">
              <SelectDropdownComponent labelName={'Exposure Type'} options={exposureTypeOptions} currValue={exposureTypeFilter} onChangeHandler={exposureTypeChangeHandler} />
            </div>
            <div className="form-control w-full max-w-xs">
              <SelectDropdownComponent labelName={'Engaged By'} options={engagedByOptions} currValue={engagedBy} onChangeHandler={engagedByChangeHandler} />
            </div>



            {/*                     
                        <div className="flex flex-wrap gap-2">
                            {tags.map((tag, index) => (
                                        <div key={index} className="flex items-center gap-2">
                            <div
                            key={index}
                            className="bg-blue-500 text-white rounded-full px-3 py-1"
                            >
                            {tag}
                            </div>
                            </div>

  
                            
                        ))}
                        </div> */}

          </div>
          <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="flex w-full p-4 mt-4 component-preview items-center gap-2 font-sans">
              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <label className="text-sm"><b>Land Bookmark</b></label>
                  </div>

                </div>
              </div>



              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <Checkbox color="primary" value={oneAcrePremium} onChange={oneAcrePremiumChangeHandler} checked={oneAcrePremium} />
                    <label className="ml-2 text-sm">Suitable for One Acre Premium</label>
                  </div>

                </div>
              </div>

              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <Checkbox color="primary" value={chance} onChange={chanceChangeHandler} checked={chance} />
                    <label className="ml-2 text-sm">Suitable for Chance</label>
                  </div>

                </div>
              </div>

              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <Checkbox color="primary" value={siteVerification} onChange={siteVerificationChangeHandler} checked={siteVerification} />
                    <label className="ml-2 text-sm">Suitable for Site Verification</label>
                  </div>

                </div>
              </div>

              <div className="form-control w-full max-w-xs">
                <div className="flex items-center">
                  <div className="w-full flex items-center">
                    <Checkbox color="primary" value={investors} onChange={investorsChangeHandler} checked={investors} />
                    <label className="ml-2 text-sm">Suitable for Investors</label>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>

        {addPage
          ? (
            <div className="flex w-full items-center justify-center pt-10 pb-2">
              <Button color="primary" className="w-2/5" onClick={plotSubmitHandler}>Add Plot</Button>
            </div>

          ) : (
            <div className="flex w-full items-center justify-center  pt-10 pb-2">
              <Button color="primary" className="w-2/5" onClick={plotSubmitHandler}>Update Plot</Button>
            </div>

          )}
        {errorMessage && (
          <div className="flex w-full items-center justify-center">
            <label className="label text-red-500 text-sm">{errorMessage}</label>
          </div>
        )}
      </div>

      {notification.show && <NotificationComponent message={notification.message} type={notification.type} />}
    </div >


  )

}
